<template>
    <main class="ticket-component">
        <b-container v-if="isTicket">
            <b-col cols="12" class="spaceTitle">
                <div class="arrowBack text-center d-block d-md-none" @click="$router.go(-1)">
                    <font-awesome-icon icon="fa-solid fa-chevron-left" />
                </div>
                <p>{{ isTicket.subject }}</p>
            </b-col>
            <b-col cols="12" class="detailTicket">
                <p v-if="isTicket.status == 'open'">
                    <font-awesome-icon class="iconDatailTicket" icon="fa-regular fa-square-minus" />{{ $t('helper.opened_ticket') }}
                </p>
                <p v-else>
                    <font-awesome-icon class="iconDatailTicket" icon="fa-solid fa-ban" />{{ $t('helper.closed_ticket') }}
                </p>
            </b-col>
            <b-col cols="12" class="detailTicket">
                <p>
                    <font-awesome-icon class="iconDatailTicket" icon="fa-regular fa-clock" />{{ $t('helper.updated_ticket') }} {{ isTicket.updated_at | moment("DD/MM/YYYY HH:mm") }}
                </p>
            </b-col>
            <b-col cols="12" v-if="isTicket.status !== 'concluded'">
                <button class="closeTicket mb-3" v-on:click="concluir(isTicket.id)"> {{ $t('helper.close_ticket') }}</button>
            </b-col>
            <b-col class="ticketBox" :class="{ 'h-100': isTicket.status !== 'open', 'h-350': isTicket.status === 'open' }">
                <b-col cols="12" id="spaceMsgs" class="ticketMessage" v-for="item in ticketData" :key="item.id">
                    <div v-if="item.message !== null">
                        <div class="textInfoMember">
                            <img v-if="item.member.picture" class="picProfile" :src="item.member.picture" alt="perfil" />
                            <div v-else class="divNoPicProfile" :style="{ backgroundColor: randomBgColor }">
                                <p :style="{ color: randomColor }">{{ firstLetter(item.member.name) }}</p>
                            </div>
                            <div class="text1">
                                {{ item.member.name }}
                                <br>
                                <p class="textEmail">{{ item.member.email }}</p>
                            </div>
                            <div class="text2">
                                {{ item.created_at | moment("DD/MM/YYYY HH:mm:ss") }}
                            </div>
                        </div>
                        <p class="txtMsg animationOpacity">
                            {{ item.message }}
                        </p>
                    </div>
                    <div v-else>
                        <div>
                            <div class="textInfoMember">
                                <img v-if="item.member.picture" class="picProfile" :src="item.member.picture" alt="perfil" />
                                <div v-else class="divNoPicProfile" :style="{ backgroundColor: randomBgColor }">
                                    <p :style="{ color: randomColor }">{{ firstLetter(item.member.name) }}</p>
                                </div>
                                <div class="text1">
                                    {{ item.member.name }}
                                    <br>
                                    <p class="textEmail">{{ item.member.email }}</p>
                                </div>
                                <div class="text2">
                                    {{ item.created_at | moment("DD/MM/YYYY HH:mm:ss") }}
                                </div>
                            </div>
                        </div>
                        <div :class="{ imgDivMsgMy: item.is_mine, imgDivMsg: !item.is_mine }">
                            <Lightbox :thumbnail="item.media_url" :images="item.media_url"></Lightbox>
                        </div>
                    </div>
                </b-col>
            </b-col>
            <b-col class="spaceInputs" v-if="isTicket.status !== 'concluded'">
                <div class="divSpaceInputs">
                    <div class="d-flex">
                        <div class="divPicSpaceInputs">
                            <img v-if="isTicket.member.picture" class="picProfile" :src="isTicket.member.picture" alt="perfil" />
                            <div v-else class="divNoPicProfile" :style="{ backgroundColor: randomBgColor }">
                                <p :style="{ color: randomColor }">{{ firstLetter(isTicket.member.name) }}</p>
                            </div>
                        </div>
                        <div class="divReply">
                            <p class="divReply1">{{ $t('helper.reply') }}</p>
                            <p class="divReply2">{{ $t('helper.to') }}</p>
                            <p class="divReply3">{{ isTicket.member.name }}</p>
                        </div>
                    </div>
                </div>

                <div style="position: relative;">
                    <b-form-textarea id="textarea" v-model="descricaoChamado" :placeholder="$t('helper.modal.input3')" class="textAreaReply" :class="{ 'border-red': descricaoChamadoExcedido }" rows="5" max-rows="6" v-on:keyup.enter="createMsg" v-if="!midiaUpload">
                    </b-form-textarea>
                    <p class="countLength" :style="{ color: descricaoChamado.length > 255 ? 'red' : '' }">
                        {{ descricaoChamado.length }} / 255
                    </p>
                </div>
                <vue-upload-multiple-image v-if="midiaUpload" class="animationOpacity toCenterUpload" @upload-success="uploadImageSuccess" @before-remove="beforeRemove" @edit-image="editImage" @data-change="dataChange" :data-images="images" :multiple="false" :primaryText="''" :popupText="$t('helper.modal.input4')" :dragText="$t('helper.modal.input5')" :browseText="$t('helper.modal.input6')"></vue-upload-multiple-image>

            </b-col>
            <b-col class="mt-3 mb-3" v-if="isTicket.status !== 'concluded'">
                <b-col cols="12" class="p-0" v-if="!midiaUpload">
                    <div class="spaceFooter animationOpacity">
                        <button class="btnNewArchive" @click="midiaUpload = true">{{ $t('helper.add_archive') }}</button>
                        <button class="btnNewChamado" @click="createMsg">{{$t('helper.modal.input7')}}</button>
                    </div>
                </b-col>
                <b-col cols="12" class="p-0" v-if="midiaUpload">
                    <div class="spaceFooter animationOpacity">
                        <button class="btnBack" @click="midiaUpload = false">{{ $t('helper.back') }}</button>
                        <button class="btnNewChamado" @click="createMsgImg">{{$t('helper.modal.input8')}}</button>
                    </div>
                </b-col>
            </b-col>
        </b-container>
    </main>
</template>

<script>
import TicketService from "@/services/resources/TicketService";
const serviceTicket = TicketService.build();
import VueUploadMultipleImage from "vue-upload-multiple-image";
import notify from "@/services/libs/notificacao";
import Lightbox from "@/components/Lightbox.vue";
export default {
    components: {  
        VueUploadMultipleImage,
        Lightbox,    
    },
    data: () => ({
        randomColor: '',
        randomBgColor: '',
        ticketSelected: '',
        idTicket: '',
        midiaUpload: false,
        imageSelected: [],
        descricaoChamado: "",
        ticket: null,
        ticketData: null,
        items: [],
        isTicket: null,
        statusTicket: [],
        images: [],
    }),
    mounted() {
        this.getTicketStats();
        this.view();
        this.randomColor = this.getRandomRgbColor();
        this.randomBgColor = this.addOpacity(this.randomColor, 0.4);
        this.idTicket = this.$route.params.id;
    },
    computed: {
        theme() {
            return this.$store.state.globalSettings.theme;
        },
        clubVersion() {
            return this.$store.state.globalSettings.clubVersion;
        },
        isMobile() {
            return this.client.width <= 576;
        },
        descricaoChamadoExcedido() {
            return this.descricaoChamado.length > 255;
        }
    },
    methods: {
        concluir(idRow) {
            let data = {
                id: idRow,
                status: "concluded",
            };
            this.loading = true;
            serviceTicket
                .postID(data)
                .then((resp) => {
                    this.$router.go(-1);
                    notify('sucesso', 'Chamado concluido!');
                })
                .finally((err) => {
                    this.loading = false;
                });
        },
        firstLetter(name) {
            return name.charAt(0).toUpperCase();
        },
        getRandomRgbColor() {
            let color = 'rgb(';
            for (let i = 0; i < 3; i++) {
                color += Math.floor(Math.random() * 256) + ',';
            }
            color = color.slice(0, -1);
            color += ')';
            return color;
        },
        addOpacity(rgbColor, opacity) {
            return rgbColor.replace('rgb', 'rgba').replace(')', `,${opacity})`);
        },
        async getTicketStats() {
            this.loading = true;
            let dateUrl = '/show';
            await serviceTicket
                .read(this.$route.params.id + dateUrl)
                .then((resp) => {
                    this.isTicket = resp;
                    this.loading = false;
                })
                .catch((err) => {
                    this.loading = false;
                });
        },

        view() {
            serviceTicket
                .read(this.$route.params.id)
                .then((resp) => {
                    this.ticketData = resp.data.reverse();
                    this.loading = false;
                    this.scroll();
                })
                .catch((err) => {
                    this.loading = false;
                });
        },
        createMsgImg() {
            this.loading = true;
            let data = {
                id: this.idTicket + "/message",
                media: this.imageSelected.path,
            };
            serviceTicket
                .postID(data)
                .then((resp) => {
                    this.descricaoChamado = "";
                    this.imageSelected = {};
                    this.images = [];
                    this.$root.$emit("bv::hide::modal", "modal-new-helper", "#btnShow");
                    this.loading = false;
                })
                .catch((err) => {
                    this.loading = false;
                }).finally(() => {
                    this.view(this.idTicket);
                    this.loading = false;
                });;
        },
        createMsg() {
            this.loading = true;
            let data = {
                id: this.idTicket + "/message",
                message: this.descricaoChamado,
            };
            serviceTicket
                .postID(data)
                .then((resp) => {
                    this.descricaoChamado = "";
                    this.imageSelected = {};
                    this.images = [];
                    this.$root.$emit("bv::hide::modal", "modal-new-helper", "#btnShow");
                    this.loading = false;
                })
                .catch((err) => {
                    this.loading = false;
                    var error = JSON.parse(err.response.data);
                    var msg = '';
                    for (var indice in error) {
                        msg += error[indice][0] + "<br>";
                    }
                    if (msg !== '') {
                        notify('erro', msg);
                    }
                }).finally(() => {
                    this.view(this.idTicket);
                    this.loading = false;
                });
        },
        uploadImageSuccess(formData, index, fileList) {
            this.imageSelected = fileList[0];
        },
        dataChange(data) {

        },
        editImage(formData, index, fileList) {

        },
        beforeRemove(index, done, fileList) {
            var r = confirm(this.$t('helper.modal.remove_image'));
            if (r == true) {
                done();
            }
        },
        scroll() {
            setTimeout(() => {
                var objScrDiv = document.getElementById("spaceMsgs");
                objScrDiv.scrollTop = objScrDiv.scrollHeight;
            }, 1000);
        },
    },
}
</script>

<style lang="scss" scoped>
.ticketMessage {
    color: var(--text-color);
    display: flex;
    flex-direction: column;
}

.ticket-component {
    padding: 35px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.spaceTitle {
    display: flex;
    justify-content: space-between;
    align-items: center;

    p {
        width: 100%;
        height: 30px;
        font-family: "Montserrat Alternates";
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 29px;
        color: var(--text-color);
        margin: 0px;
    }

    @media (max-width: 768px) {
        border-bottom: 1px solid var(--background2-v2);
        padding-top: 20px;
        padding-bottom: 20px;
        margin-bottom: 16px;

        &::after {
            content: '';
            width: 35px;
        }

        p {
            text-align: center;
            font-size: 18px;
            word-break: break-all;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            word-break: break-all;
            padding-left: 15px;
        }
    }
}

.arrowBack {
    border-radius: 100%;
    background: var(--background2-v2) !important;
    width: 35px;
    height: 35px;
    padding: 8px;
    color: var(--text-color);
}

.iconDatailTicket {
    width: 16px;
    height: 16px;
    margin-right: 8px;
}

.detailTicket {
    p {
        display: flex;
        align-items: center;
        width: max-content;
        color: var(--text-color);
        margin-right: 8px;
    }
}

.closeTicket {
    width: 100%;
    height: 40px;
    border: 1px solid var(--text-color);
    color: var(--text-color);
    border-radius: 50px;
    background-color: transparent;
}

.textInfoMember {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;

    .text1 {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        letter-spacing: 0.2px;
        color: var(--text-color);
        order: 2;
        text-align: start;
        width: 100%;
        margin-left: 16px;
    }

    .textEmail {
        color: var(--fontcolor2-v2);

    }

    .text1 p {
        margin: 0;
    }

    .text2 {
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        letter-spacing: 0.2px;
        color: var(--fontcolor2-v2);
        order: 3;
        text-align: right;
        width: 100%;
    }
}

.divNoPicProfile {
    display: grid;
    place-items: center;
    border-radius: 100%;

    width: 32px;
    height: 32px;
    flex-shrink: 0;
}

.divNoPicProfile p {
    margin: 0;
    font-size: 16px;
}

.picProfile {
    width: 32px;
    height: 32px;
    position: relative;
    margin: 0;
    border-radius: 50px;
    order: 1;

    img {
        width: 100%;
        border-radius: 100%;
    }
}

.txtMsg {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    margin-bottom: 18px;
    color: var(--fontcolor2-v2);
    word-break: break-all;
}

.ticketBox {
    height: 350px;
    padding: 0;
    overflow: auto;

}

.btnNewChamado {
    box-sizing: border-box;
    height: 40px;
    background: #01CAA6;
    border-radius: 50px;
    border: none;
    font-size: 13px;
    padding-left: 16px;
    padding-right: 16px;
}

.btnNewArchive {
    border: none;
    background-color: transparent;
    color: #01CAA6;
    margin-bottom: 8px;
    margin-bottom: 0;
    height: 40px;
    font-size: 13px;
    padding: 0;
}

.spaceInputs {
    margin-bottom: 0px;
    margin-top: 12px;

    .toCenterUpload {
        display: flex;
        justify-content: center;
    }

}

.divPicSpaceInputs {
    border-radius: 150px;
    width: 32px;
    height: 32px;

}

.divSpaceInputs {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 4px;
}

.divReply3 {
    margin-left: 4px;
    margin-bottom: 0;
    color: var(--fontcolor2-v2);
    background: var(--background2-v2);
    border-radius: 5px;
    padding-left: 8px;
    padding-right: 8px;
    font-size: 13px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 148px;
}

.divReply2 {
    color: var(--fontcolor2-v2);
    margin-bottom: 0;
    font-size: 13px;
}

.divReply1 {
    color: var(--text-color);
    font-size: 14px;
    margin-right: 4px;
    margin-bottom: 0;
}

.divReply {
    display: flex;
    align-items: center;
    margin-right: 4px;
    margin-bottom: 0;
    margin-left: 8px;
}

.textAreaReply {
    width: 100%;
    background: var(--background2-v2) !important;
    border-radius: 20px;
    border: none !important;
    padding: 5px 25 !important;
    padding-right: 20px !important;
    padding-top: 15px !important;
    padding-bottom: 15px !important;
    font-size: 13px !important;
    color: var(--fontcolor-v2) !important;
    height: 140px !important;
    overflow: hidden !important;
    resize: none !important;
    margin-right: 0px;
    margin-left: 0px;
    margin-top: 16px;

}

.spaceFooter {
    display: flex;
    justify-content: space-between;
    padding: 0;
}

.btnBack {
    box-sizing: border-box;
    width: 123px;
    height: 40px;
    background: #01caa563;
    border-radius: 50px;
    border: none;
    margin-right: 16px;
    font-size: 13px;
}

.ticketBox::-webkit-scrollbar {
    width: 2px;
}

.ticketBox::-webkit-scrollbar-track {}

.ticketBox::-webkit-scrollbar-thumb {
    background-color: var(--fontcolor);
    border-radius: 31px;
}

.ticketBox::-webkit-scrollbar-button {
    display: none;
}

.imgDivMsgMy {
    max-width: 128px;
    min-height: 128px;
    border-radius: 3px;
    margin-bottom: 16px;
}

.imgDivMsg {
    max-width: 128px;
    min-height: 128px;
    border-radius: 3px;
    margin-bottom: 16px;
}

.border-red {
    outline: none !important;
    border-color: #ff0e0e !important;
    box-shadow: 0 0 5px #ff0e0e !important;
    border: none !important;
}

.countLength {
    position: absolute;
    bottom: 2px;
    right: 10px;
    font-size: 10px;
    color: #01CAA6;
    margin-bottom: 0;
}

.h-350 {
    height: 350px;
}
</style>
